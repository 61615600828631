












































































































































import useMisc from "@/use/misc";
import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  components: {
    MEditor: () => import("@/components/molecules/editor/m-editor.vue"),
  },
  setup(_, { root }) {
    const { goBack } = useMisc({ root });
    const model = reactive({
      ordinalNumber: "0",
      name: "",
      englishName: "",
      additionalServiceGroup: "",
      isEnabled: false,
      hasReserveList: false,
      type: "without text field",
      price: 0.0,
      amount: "",
      discountIds: "",
      participantGroupIds: [] as string[],
      additionalInformation: "",
    });

    const state = reactive({
      unlimitedAmount: false,
      loading: false,
      loaded: false,
      success: false,
      error: false as boolean | number,
      discounts: [],
      participantGroups: [],
      additionalServiceGroups: [],
      valid: false,
      reserveListAllowed: false,
    });

    const rules = {
      required: [(v: string) => !!v || `${root.$t("layout.misc.required")}`],
    };

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return `${root.$tc("layout.errors.noPermission")}`;
        case 404:
          return `${root.$tc(
            "epanel.event.modules.additionalService.add.error404"
          )}`;
        case 409:
          return `${root.$tc(
            "panel.event.modules.additionalService.add.error409"
          )}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`additional-service/${root.$route.params.asid}`)
        .then(({ data }) => {
          model.ordinalNumber = data.ordinalNumber;
          model.name = data.name;
          model.englishName = data.englishName;
          model.additionalServiceGroup = data.group.id;
          model.isEnabled = data.isEnabled;
          model.hasReserveList = data.hasReserveList;
          model.type = data.type;
          model.price = data.price;
          model.amount = data.amount;
          model.discountIds = data.discounts.map((d: any) => d.id);
          model.participantGroupIds = data.participantGroups.map(
            (pg: any) => pg.id
          );
          model.additionalInformation = data.additionalInformation;
          state.unlimitedAmount =
            data.amount >= 0 && data.amount !== null ? true : false;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchData);

    const fetchDiscounts = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/discount`)
        .then(({ data: { discounts } }) => {
          state.discounts = discounts
            .filter((el: any) => !el.isGlobal)
            .map((d: any) => ({
              id: d.id,
              name: d.name,
            }));
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchDiscounts);

    const fetchParticipantGroups = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/participant/group/select`)
        .then(({ data: { groups } }) => {
          state.participantGroups = groups.map((g: any) => ({
            id: g.id,
            name: g.name,
          }));
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.participantGroups = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchParticipantGroups);

    const checkServiceGroupAmount = () => {
      state.additionalServiceGroups.filter((el: any) => {
        if (model.additionalServiceGroup === el.id) {
          if (el.limit > 1) {
            state.reserveListAllowed = false;
          } else state.reserveListAllowed = true;
        }
      });
    };

    watch(() => model.additionalServiceGroup, checkServiceGroupAmount);

    const fetchServiceGroups = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/additional-service-group`)
        .then(({ data: { additionalServiceGroups } }) => {
          state.additionalServiceGroups = additionalServiceGroups;
          checkServiceGroupAmount();
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.additionalServiceGroups = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchServiceGroups);

    const onSubmit = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        ordinalNumber: parseFloat(model.ordinalNumber),
        name: model.name,
        englishName: model.englishName,
        isEnabled: model.isEnabled,
        hasReserveList: model.hasReserveList,
        type: model.type,
        price: model.price,
        amount: state.unlimitedAmount ? parseFloat(model.amount) : null,
        additionalInformation: model.additionalInformation || undefined,
        participantGroupIds: model.participantGroupIds || [],
        discountIds: model.discountIds || [],
        additionalServiceGroupId: model.additionalServiceGroup,
      };

      state.loading = true;

      axiosInstance
        .put(`additional-service/${root.$route.params.asid}`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.success = true;
          state.error = false;

          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "primary",
            message: root.$tc(
              "panel.event.modules.additionalService.edit.success"
            ),
          });
          setTimeout(() => {
            goBack("panel.event.view.additionalService.list");
          }, 500);
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => (state.loading = false));
    };

    return {
      model,
      state,
      rules,
      onSubmit,
    };
  },
});
